import axios from 'axios';
import ClientConfig from './clientConfig';

export class PromoCodeServiceClient {
   
    async promoCodeCheck(promoCode, basePrice, callback){

        try {
            await
                axios.get(ClientConfig.ApiBaseUrl + "promo/check/" + promoCode +"/"+ basePrice,
                    
                    {
                        headers:
                        {
                            'Content-Type': 'application/json',
                        }
                    }
                ).then(response => {
                   callback(response.data);
                });
        }
        catch (e) {
            console.log('CertService error.', e);
        }
    }
 
}

export default PromoCodeServiceClient;
