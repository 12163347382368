import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
//import imagePng from "images/Lesson.gif";
import movie1 from "images/v1a.mp4";
import movie2 from "images/v2a.mp4";
import StartRating from "components/StartRating/StartRating";


export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {

  var videoIndex = 1;
  const scrollToList = () => {
    const element = document.getElementsByClassName('clist')[0];
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };


  return (
    <div
      className={`nc-SectionHero3 relative ${className}`}
      data-nc-id="SectionHero3"
    >
      <div className="absolute z-10 inset-x-0 top-[75%] text-center flex flex-col items-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
        {/* <span className="sm:text-lg md:text-xl font-semibold text-neutral-900">
          dr Aleksandra Szyller
        </span>
        <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
          SZKOLENIA<br />DLA NAUCZYCIELI
        </h2> */}
        <ButtonPrimary
          sizeClass="px-6 py-3 lg:px-8 lg:py-4 rounded-xl"
          fontSize="text-sm sm:text-base lg:text-lg font-medium"
          onClick={scrollToList}
        >
          Najbliższe szkolenia
        </ButtonPrimary>
   
      </div>
      <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">

        <video autoPlay={true} muted playsInline={true} loop={false} id="myVideo" onEnded={(e) => {
          (e.target as any).src = (videoIndex % 2) == 0 ? movie1 : movie2;  //"/images/test" + (videoIndex % 2) + ".mp4";
          videoIndex++;

        }} className="absolute inset-0 object-cover rounded-xl videov">
          <source src={movie1} type="video/mp4"></source>
        </video>
        <div className="inset-0 object-cover rounded-xl videof flex items-center justify-center" >
          <img className="inset-0 object-cover rounded-xl align-middle" src="/images/cover.png"></img>




          
          <div className="absolute right-10 top-5 flex items-center space-x-4">
            <a href="https://www.facebook.com/profile.php?id=100063928610127">
              <img className="w-7 w-7" src="/images/fb_logo.png"></img>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100063928610127">
              <StartRating point={"5.0"} reviewCount={57} />
            </a>

          </div>


          {/* <a href="https://www.facebook.com/profile.php?id=100063928610127">
            <img className="absolute right-10 top-5 lg:w-20 w-10 md:w-15 sm:w-10" src="/images/fb_logo.png"></img>
          </a> */}
        </div>


      </div>
    </div>
  );
};

export default SectionHero3;
