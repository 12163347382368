import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";

export interface SectionHowItWorkProps {
  className?: string;
  data?: {
    id: number;
    title: string;
    desc: string;
    img: string;
    imgDark?: string;
    descfn?: any;
  }[];
}

const DEMO_DATA: SectionHowItWorkProps["data"] = [
  {
    id: 1,
    img: HIW1img,
    title: "Zapisy",
    desc: "Łatwa i szybka rejestracja na szkolenie indywidualne lub grupowe",
  },
  {
    id: 2,
    img: HIW2img,
    title: "Płatność",
    desc: "Wszystkie dostępne szkolenia są w tej samej cenie 100zł brutto za osobę. możliwość wystawienia faktury z odroczonym terminem płatności). Istnieje możliwość udzielenia rabatu (w momencie zgłoszenia się kilku osób z danej placówki) lub zebrania większej grupy szkoleniowej. Rabat jest udzielany także przy uczestnictwie w kilku szkoleniach. W przypadku chęci organizacji szkolenia dla rad pedagogicznych (zdalnego lub stacjonarnego) cenę ustalamy indywidualnie. Proszę o kontakt mailowy.",
  },
  {
    id: 3,
    img: HIW3img,
    title: "Dostęp do platformy",
    desc: "Dzień przed szkoleniem otrzymają Państwo link do platformy MS Teams.",
  },
  {
    id: 4,
    img: HIW3img,
    title: "Szkolenie",
    desc: "Standardowe szkolenie zdalne trwa 5h dydaktycznych (3h 45 min)",
  },
  {
    id: 5,
    img: HIW3img,
    title: "Materiały",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
  {
    id: 6,
    img: HIW3img,
    title: "Certyfikaty",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
  {
    id: 7,
    img: HIW3img,
    title: "Zniżki",
    desc: "Let each trip be an inspirational journey, each room a peaceful space",
  },
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  return (
    <div
      className={`nc-SectionHowItWork  ${className}`}
      data-nc-id="SectionHowItWork"
    >
      <Heading isCenter desc="">
        Jak to działa?
      </Heading>
      <div className="mt-20 relative grid md:grid-cols-3 gap-20">
        <img
          className="hidden md:block absolute inset-x-0 top-10"
          src={VectorImg}
          alt=""
        />
        {data.map((item) => (
          <div
            key={item.id}
            className="relative flex flex-col items-center max-w-xs mx-auto"
          >
            {item.imgDark ? (
              <>
                <NcImage
                  containerClassName="dark:hidden block mb-8 max-w-[200px] mx-auto"
                  src={item.img}
                />
                <NcImage
                  containerClassName="hidden dark:block mb-8 max-w-[200px] mx-auto"
                  src={item.imgDark}
                />
              </>
            ) : (
              <NcImage
                containerClassName="mb-8 max-w-[200px] mx-auto"
                src={item.img}
              />
            )}
            <div className="text-center">
              <h3 className="text-xl font-semibold">{item.title}</h3>
              <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
                { item.descfn == null ? item.desc : item.descfn() }
             
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionHowItWork;
