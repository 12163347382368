import CommonLayout from "containers/AccountPage/CommonLayout";
import FormItem from "containers/PageAddListing1/FormItem";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import React, { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";

export interface PagePrivacyProps { }

const PagePrivacy: FC<PagePrivacyProps> = () => {
    return (
        <div className="nc-PageHome3 relative overflow-hidden">

            {/* SECTION HERO */}
            <div className="container px-1 sm:px-4 mb-24 ">

            </div>
            <div className="container flex">
                <div className="listingSection__wrap">
                    <h2 className="text-2xl font-semibold">Polityka Prywatności</h2>
                    <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

                    <div className="text-neutral-6000 dark:text-neutral-300">

                        <p>Polityka prywatności strony internetowej <strong><a href="https://mamtemoc.edu.pl/">https://mamtemoc.edu.pl/</a></strong> dotyczącej sprzedaży szkoleń on-line prowadzonych na platformie MS-Teams.</p>


                        <p className="mt-5">Administratorem danych osobowych jest firma <strong>Mam Tę Moc Aleksandra Szyller</strong> z siedzibą w Warszawie (02-677) przy ul. Cybernetyki 4a/172, zarejestrowana w Krajowym Rejestrze Sądowym pod numerem <strong>NIP 5213584358, REGON 385841416</strong>.</p>
                        <p className="mt-5">Firma jest podmiotem odpowiedzialnym za przetwarzanie danych osobowych użytkowników strony internetowej zgodnie z obowiązującymi przepisami prawa oraz niniejszą polityką prywatności.</p>

                        <p>W razie pytań lub wątpliwości dotyczących przetwarzania danych osobowych, prosimy o kontakt pod adresem e-mail: szyllerola@mamtemoc.edu.pl. </p>


                        <h2 className="text-1xl font-semibold mt-10 mb-5">1. Zbieranie informacji osobowych</h2>
                        <p className="mb-2">
                            Podczas rejestracji na szkolenie za pomocą strony internetowej, Klient zostanie poproszony o podanie swojego imienia, nazwiska, adresu e-mail i informacji nie zbędnych do wystawienia faktury (opcjonalnie).
                        </p>

                        <h2 className="text-1xl font-semibold mt-10 mb-5">2. Przetwarzanie informacji osobowych</h2>
                        <p className="mb-2">
                            Zebrane dane są niezbędne do otrzymania dostępu do zakupionego szkolenia, jak również wysyłki materiałów i wystawienia certyfikatów o ukończeniu szkolenia.
                            Zgodnie z RODO, okres przechowywania danych osobowych jest ograniczony do minimum. Jednak informacje takie jak imię, nazwisko i adres email są niezbędne dla zapewnienia możliwości potwierdzenia autentyczności certyfikatu, jak również wystawienia duplikatów.
                        </p>

                        <h2 className="text-1xl font-semibold mt-10 mb-5">3. Bezpieczeństwo danych osobowych</h2>
                        <p className="mb-2">
                            Strona internetowa https://mamtemoc.edu.pl/ zapewnia odpowiednie środki techniczne i organizacyjne w celu ochrony danych osobowych Klientów przed nieuprawnionym dostępem, zmianą, utratą lub uszkodzeniem. Strona stosuje standardowe protokoły bezpieczeństwa, które zapewniają szyfrowanie danych i ochronę przed podsłuchiwaniem.
                        </p>

                        <h2 className="text-1xl font-semibold mt-10 mb-5">4. Udostępnianie danych osobowych</h2>
                        <p className="mb-2">
                            Strona internetowa https://mamtemoc.edu.pl/ <strong>nie udostępnia</strong> danych osobowych Klientów osobom trzecim.
                        </p>
                   

                        <h2 className="text-1xl font-semibold mt-10 mb-5">5. Prawa Klienta</h2>
                        <p className="mb-2">
                            Klient ma prawo wglądu do swoich danych osobowych oraz ich poprawiania, usunięcia lub ograniczenia przetwarzania. Klient ma również prawo do wniesienia skargi do organu nadzorczego, jeśli uważa, że przetwarzanie danych osobowych narusza przepisy o ochronie danych osobowych.
                        </p>

                        <h2 className="text-1xl font-semibold mt-10 mb-5">6. Ciasteczka</h2>
                        <p className="mb-2">
                            Nasza witryna wykorzystuje ciasteczka, czyli pliki tekstowe przechowywane na urządzeniu użytkownika (np. komputerze, tablecie, smartfonie), które umożliwiają analizowanie zachowania użytkownika na naszej stronie oraz personalizację treści i reklam. Wśród wykorzystywanych przez nas rodzajów ciasteczek znajdują się:
                            <ul className="ul-def mt-5 mb-5">
                                <li>ciasteczka niezbędne do działania strony,</li>
                                <li>ciasteczka analityczne, które umożliwiają nam analizowanie zachowania użytkowników na stronie,</li>
                                <li>ciasteczka funkcjonalne, które umożliwiają personalizację treści i ustawień strony dla użytkownika.</li>
                            </ul>
                            Możesz wyłączyć lub zmienić ustawienia dotyczące ciasteczek w swojej przeglądarce internetowej. Szczegółowe informacje na ten temat znajdziesz w sekcji pomocy swojej przeglądarki. Pamiętaj jednak, że wyłączenie lub ograniczenie ciasteczek może wpłynąć na funkcjonalność strony oraz ograniczyć dostępność niektórych funkcji.

                        </p>

                        <h2 className="text-1xl font-semibold mt-10 mb-5">7. Kontakt</h2>
                        <p className="mb-2">
                            W przypadku pytań lub wątpliwości dotyczących polityki prywatności lub przetwarzania danych osobowych, Klient może skontaktować się z nami za pomocą adresu e-mail: szyllerola@mamtemoc.edu.pl lub numeru telefonu: +48 501 192 957.
                        </p>

                    </div>
                </div>

            </div>

        </div>
    );
};

export default PagePrivacy;
