import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";

export interface SectionBecomeAnAuthorProps {
  className?: string;
  rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
  className = "",
  rightImg = rightImgDemo,
}) => {
  return (
    <>
      <div
        className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center  ${className}`}
        data-nc-id="SectionBecomeAnAuthor"
      >
        <div className="flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
          {/* <Logo className="w-20" /> */}
          <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
            Tematyka
          </h2>
          <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
            Zapraszam na samodzielnie prowadzone, autorskie szkolenia dla nauczycieli edukacji przedszkolnej i wczesnoszkolnej. Tematy prowadzonych przeze mnie szkoleń oraz poruszane w ramach ich realizacji zagadnienia znajdą Państwo w katalogu. Jeżeli nie znaleźli Państwo poszukiwanego tematu szkolenia, proszę o kontakt mailowy. Możliwe są szkolenia także z innych tematów.
          </span>
          <h2 className="font-semibold text-3xl sm:text-4xl mt-6 sm:mt-11">
            Webinaria
          </h2>
          <span className="block mt-6 text-neutral-500 dark:text-neutral-400">
            Wszystkie poniższe szkolenia są prowadzone online. Szkolenia organizowane są w formie webinariów na żywo. W trakcie webinariów istnieje możliwość komunikacji za pomocą mikrofonu oraz komunikatora. Nie ma konieczności włączania kamery. Po szkoleniu wysyłam Państwu prezentację oraz wszelkie materiały. Koszt szkolenia online wynosi 100 zł. Istnieje możliwość wystawienia faktury VAT 0% (także na osoby fizyczne oraz z odroczoną płatnością). Każde szkolenie może być zorganizowane w formie stacjonarnej, z dojazdem do placówki. Koszt ustalany jest indywidualnie.
          </span>
          {/* <ButtonPrimary className="mt-6 sm:mt-11">
            Become an author
          </ButtonPrimary> */}
        </div>
        <div className="flex-grow">
          <NcImage src={rightImg} />
        </div>
      </div>
    </>
  );
};

export default SectionBecomeAnAuthor;
