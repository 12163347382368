import React, { useEffect, useState } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionHero3 from "components/SectionHero/SectionHero3";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import SectionGridFilterCard from "containers/ListingFlightsPage/SectionGridFilterCard";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHero from "containers/PageAbout/SectionHero";
import Heading from "components/Heading/Heading";
import FlightCard from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import EventServiceClient from "services/eventServiceClient";
import EventModel from "services/models/eventModel";
import OverviewCard from "components/FlightCard/OverviewCard";
import NcModal from "shared/NcModal/NcModal";




const Demo_data: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  }
];

function OverviewHome() {
  // CUSTOM THEME STYLE
  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;
    $body.classList.add("theme-purple-blueGrey");
    return () => {
      $body.classList.remove("theme-purple-blueGrey");
    };
  }, []);


  const [courseList, setCourseList] = useState<any[] | []>([]);

  useEffect(() => {
    new EventServiceClient().geta((data: any) => {
      setCourseList(data);
    });
  }, []);



  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container max-w-27xl mx-auto">

      </div>


    
      <div className="container flex">
    

</div>      

      <div className="container relative mt-24 mb-24">
      <div
      className={`nc-SectionGridFilterCard className="clist pb-24 lg:pb-28 mt-10"`}
      data-nc-id="SectionGridFilterCard"
    >

      <Heading isCenter desc="">
        Pomoce
      </Heading>
      {/* <NcModal isOpenProp={true}  modalTitle="Generuj Certyfikaty"
      renderContent={  ()=>
      <div>
        
        <ButtonPrimary>Ok</ButtonPrimary>
        </div>} 
      
    ></NcModal> */}
  

      {/* <div className="mb-8 lg:mb-11">
        <TabFilters />                                                                                                                                                                                                                                                                                                                                              
      </div> */}
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {courseList.map((item, index) => (
          <OverviewCard defaultOpen={!index} key={index} data={item} cb={ ()=> { 

            new EventServiceClient().geta((data: any) => {
            
              setCourseList(data);
              
            });
           } } />
        ))}

        <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary href="/catalog">Zobacz pełną ofertę</ButtonPrimary>
        </div>
      </div>
    </div>


 

      </div>
    </div>
  );
}

export default OverviewHome;
