import axios from 'axios';
import ClientConfig from './clientConfig';

export class PaymentServiceClient {

   
    async methods(callback) {

        try {
            await
                axios.get(ClientConfig.ApiBaseUrl + "payment/methods",

                    {
                        headers:
                        {
                            'Content-Type': 'application/json',
                        }
                    }
                ).then(response => {

                    callback(response.data);
                });
        }
        catch (e) {
            console.log('EventService error.', e);
        }
    }

    async link(orderRef, paymentMethod, callback) {

        try {
            axios.post(ClientConfig.ApiBaseUrl + "payment/",
                JSON.stringify(
                    {
                        "orderRef": orderRef,
                        "paymentMethodId": paymentMethod
                    }),
                { headers: { 'Content-Type': 'application/json',
                 } }
            ).then(response => {
                callback(response.data.result);
            }).catch(aa=>
                {
                    window.location.href = "/"
                });

        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
    }
}

export default PaymentServiceClient;
