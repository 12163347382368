import axios from 'axios';


export class AuthServiceClient {


    async login(email, password, callback){

        try {
            axios.post('https://mamtemoc.edu.pl/api/auth/login',
            JSON.stringify(
                {
                    "login": email,
                    "password": password,
                }),
            { headers: { 'Content-Type': 'application/json' } }
        ).then(response => {
            var data = response.data;
            if (data.token) {
                localStorage.setItem('token', data.token);
                callback("Success");
                return;
            }
            callback("Error");

        });           
            
        }
        catch (e) {
            console.log('Sending message failed.', e);
        }
        }

       authToken() {
            let token = localStorage.getItem('token');
            return 'Bearer ' + token;
        }
    
        authHeader() {
    
            let token = localStorage.getItem('token');
            if (token) {
                return {
                    'Authorization': 'Bearer ' + token
                };
            } else {
                return {};
            }
        }

        static isAuth() {
            return  localStorage.getItem('token') ? true:false;
          }
    
}

export default AuthServiceClient;
