import CommonLayout from "containers/AccountPage/CommonLayout";
import FormItem from "containers/PageAddListing1/FormItem";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import React, { FC } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";

export interface PageRegulationsProps {}

const PageRegulations: FC<PageRegulationsProps> = () => {
  return (
    <div className="nc-PageHome3 relative overflow-hidden">

    {/* SECTION HERO */}
    <div className="container px-1 sm:px-4 mb-24 ">

    </div>
    <div className="container flex">
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">Regulamin sklepu internetowego</h2>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
            <div className="text-neutral-6000 dark:text-neutral-300">


<h2 className="text-1xl font-semibold mb-5">Postanowienia ogólne</h2>

<p className="mb-2"><strong>1.1.</strong> Niniejszy regulamin określa zasady korzystania ze sklepu internetowego dostępnego pod adresem https://mamtemoc.edu.pl.
</p>
<p className="mb-2"><strong>1.2.</strong> Sklep internetowy jest prowadzony przez <strong>MAM TĘ MOC ALEKSANDRA SZYLLER</strong>, z siedzibą w <strong>Warszawie 02-677, ul. Cybernetyki 4A lok. 172</strong>, NIP <strong>5213584358</strong>, REGON <strong>385841416</strong>, zwana dalej "Sprzedającym".</p>

<p className="mb-2"><strong>1.3.</strong> Sklep internetowy umożliwia dokonywanie zakupów przez Internet, a także uzyskanie informacji o oferowanych przez Sprzedającego szkoleniach prowadzonych na żywo na platformie MS Teams.</p>

<p className="mb-2"><strong>1.4.</strong> Korzystanie ze sklepu internetowego oznacza akceptację niniejszego regulaminu.</p>

<h2 className="text-1xl font-semibold mb-5 mt-5">Zasady składania zamówień</h2>

<p className="mb-2"><strong>2.1.</strong> Szkolenia prowadzone na żywo na platformie MS Teams oferowane przez Sprzedającego są dostępne w sklepie internetowym.</p>

<p className="mb-2"><strong>2.2.</strong> Zamówienia (zapisy) można składać przez Internet 24 godziny na dobę przez cały rok.</p>

<p className="mb-2"><strong>2.3.</strong> Aby złożyć zamówienie, należy wybrać interesujące szkolenie, kliknąć <strong>Zapisz się</strong> i wypełnić dane uczestnika szkolenia.</p>

<p className="mb-2"><strong>2.4.</strong> W formularzu rejestracji należy podać swoje dane osobowe (imię i nazwisko) oraz adres e-mail, a także wybrać formę płatności.</p>

<p className="mb-2"><strong>2.5.</strong> W celu złożenia zamówienia należy zaakceptować regulamin sklepu oraz politykę prywatności.</p>

<p className="mb-2"><strong>2.6.</strong> Po złożeniu zamówienia, Sprzedający wyśle na podany adres e-mail informację zawierającą potwierdzenie zamówienia, wraz z informacją o terminie i szczegółach szkolenia.</p>

<h2 className="text-1xl font-semibold mb-5 mt-5">Płatności</h2>
<p className="mb-2"><strong>3.1.</strong> W sklepie internetowym dostępne są następujące formy płatności: przelew bankowy</p>

<p className="mb-2"><strong>3.2.</strong> Cena każdego szkolenia prowadzonego na żywo podana w sklepie internetowym w walucie PLN (polski złoty) i zawiera podatek VAT.</p>

<p className="mb-2"><strong>3.3.</strong> Realizacja zamówienia następuje po otrzymaniu wpłaty.</p>

<h2 className="text-1xl font-semibold mb-5 mt-5">Szkolenia</h2>
<p className="mb-2"><strong>4.1.</strong> Szkolenia prowadzone są na żywo na platformie MS Teams.</p>

<p className="mb-2"><strong>4.2.</strong> Szczegóły szkolenia (data, godzina, link do spotkania) zostaną przesłane Klientowi na podany przez niego adres e-mail przed datą szkolenia.</p>


<h2 className="text-1xl font-semibold mb-5 mt-5">Reklamacje</h2>


<p className="mb-2"><strong>5.1.</strong> Klient ma prawo do reklamacji w przypadku niezgodności szkolenia z opisem lub wad materiałów szkoleniowych.</p>

<p className="mb-2"><strong>5.2.</strong> Reklamacje należy zgłaszać drogą elektroniczną lub telefonicznie na adres/numer podany na stronie sklepu internetowego.</p>

<p className="mb-2"><strong>5.3.</strong> W treści reklamacji należy podać imię i nazwisko Klienta, adres e-mail, nazwę i datę szkolenia oraz opis problemu.</p>

<p className="mb-2"><strong>5.4.</strong> Sprzedający zobowiązuje się do rozpatrzenia reklamacji w terminie 14 dni od daty otrzymania zgłoszenia.</p>

<p className="mb-2"><strong>5.5.</strong> Jeśli reklamacja zostanie uznana za zasadną, Sprzedający zobowiązuje się do naprawy problemu (błędne materiały, certyfikat, ...), lub do zwrotu kosztów zakupu.</p>

<h2 className="text-1xl font-semibold mb-5 mt-5 mb-5 mt-5">Odstąpienie od umowy</h2>
<p className="mb-2"><strong>6.1.</strong> Klient ma prawo do odstąpienia od umowy bez podania przyczyny w dowolnym momencie przed rozpoczęciem szkolenia.</p>

<p className="mb-2"><strong>6.2.</strong> Jeśli z przyczyn losowych Klient nie może (nie mógł) dołączyć do wykupionego szkolenia w wyznaczonym terminie, sprzedający zaproponuje termin alternatywny lub szkolenie o innej tematyce. (bez dodatkowych opłat)</p>

<p className="mb-2"><strong>6.3.</strong> Sprzedający zobowiązuje się do zwrotu kosztów zakupu w ciągu 14 dni od daty otrzymania zwróconego produktu.</p>

<h2 className="text-1xl font-semibold mb-5 mt-5 mb-5 mt-5">Ochrona danych osobowych</h2>
<p className="mb-2"><strong>7.1.</strong> Sprzedający zapewnia ochronę danych osobowych zgodnie z obowiązującymi przepisami prawa oraz polityką prywatności dostępną na stronie sklepu internetowego.</p>

<p className="mb-2"><strong>7.2.</strong> Klient wyraża zgodę na przetwarzanie swoich danych osobowych przez Sprzedającego w celu realizacji zamówień oraz w celach marketingowych, zgodnie z polityką prywatności.</p>

<h2 className="text-1xl font-semibold mb-5 mt-5 mb-5 mt-5">Postanowienia końcowe</h2>
<p className="mb-2"><strong>8.1.</strong> Sprzedający zastrzega sobie prawo do zmiany regulaminu sklepu internetowego.</p>

<p className="mb-2"><strong>8.2.</strong> W przypadku zmiany regulaminu, nowa wersja zostanie opublikowana na stronie sklepu internetowego.</p>

<p className="mb-2"><strong>8.3.</strong> Klient jest zobowiązany do zapoznania się z treścią regulaminu przed dokonaniem zakupu.</p>

<p className="mb-2"><strong>8.4.</strong> W sprawach nieuregulowanych w niniejszym regulaminie zastosowanie mają przepisy prawa polskiego.</p>
          
            </div>
        </div>

    </div>

</div>
  );
};

export default PageRegulations;
