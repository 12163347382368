import React, { useEffect } from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionHero3 from "components/SectionHero/SectionHero3";
import CardCategory6 from "components/CardCategory6/CardCategory6";
import SectionGridFilterCard from "containers/ListingFlightsPage/SectionGridFilterCard";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionHero from "containers/PageAbout/SectionHero";
import Heading from "components/Heading/Heading";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";

import logo1 from "images/logo-2-11.png";
import logo1Dark from "images/logo-2-11.png";

import logo2 from "images/WCIES_logo.png";
import logo2Dark from "images/WCIES_logo.png";

import steamLogo from "images/steam_logo.png";
import mscdnLogo from "images/mscdn-logo.png";
import edisonLogo from "images/edison-logo.png";

import logo3 from "images/logos/nomal/3.png";
import mscdn from "images/logomscdn-l/3.png";
import edison from "images/logoedison-l/3.png";
import logo3Dark from "images/logos/dark/3.png";

import logo4 from "images/logos/nomal/4.png";
import logo4Dark from "images/logos/dark/4.png";

import logo5 from "images/logos/nomal/5.png";
import logo5Dark from "images/logos/dark/5.png";

import HIW1img from "images/m.png";
import HIW2img from "images/HIW2-2.png";
import HIW3img from "images/platform.png";
import HIW4img from "images/course.png";
import HIW5img from "images/materials.png";
import HIW6img from "images/certificate.png";
import HIW1imgDark from "images/m.png";
import HIW2imgDark from "images/HIW2-2-dark.png";
import HIW3imgDark from "images/HIW2-3-dark.png";
import rightImgPng from "images/our-features-2.png";
import AuthorDescription from "components/SectionBecomeAnAuthor/AuthorDescription";

const demoData: TaxonomyType[] = [
  {
    id: "1",
    href: "/listing-stay",
    name: "Enjoy the great cold",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/5764100/pexels-photo-5764100.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
  },
  {
    id: "222",
    href: "/listing-stay",
    name: "Sleep in a floating way",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/2869499/pexels-photo-2869499.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "3",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "4",
    href: "/listing-stay",
    name: "Cool in the deep forest",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/247532/pexels-photo-247532.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
  {
    id: "5",
    href: "/listing-stay",
    name: "In the billionaire's house",
    taxonomy: "category",
    count: 188288,
    thumbnail:
      "https://images.pexels.com/photos/7031413/pexels-photo-7031413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260",
  },
];

function MamTeMocHome() {
  useEffect(() => {
    const $body = document.querySelector("body");
    if (!$body) return;
    $body.classList.add("theme-purple-blueGrey");
    return () => {
      $body.classList.remove("theme-purple-blueGrey");
    };
  }, []);

  return (
    <div className="nc-PageHome3 relative overflow-hidden">
      {/* GLASSMORPHISM */}
      <BgGlassmorphism />

      {/* SECTION HERO */}
      <div className="container px-1 sm:px-4 mb-24">
        <SectionHero3 className="" />
      </div>

      <div className="container">
        <div className="relative py-16">
          {/* <SectionBecomeAnAuthor rightImg="/images/i-love-mtm.png" /> */}
          <SectionBecomeAnAuthor rightImg="/images/t.png" />
        </div>

        {/* SECTION */}
        <SectionHowItWork
          data={[
            {
              id: 1,
              img: HIW1img,
              title: "Zapisy",
              desc: "Łatwa i szybka rejestracja na szkolenie indywidualne lub grupowe.",
              descfn: () => (
                <div>
                  <strong>Łatwy</strong> i <strong>bardzo uproszczony</strong>{" "}
                  proces rejestracji na szkolenie. Bez zbędnych kroków.
                  <div
                    style={{ cursor: "pointer" }}
                    className="mt-5 bolded underline decoration-purple-500"
                    onClick={() => {
                      const element = document.getElementsByClassName("clist")[0];
                      if (element) {
                        // 👇 Will scroll smoothly to the top of the next section
                        element.scrollIntoView({ behavior: "smooth" });
                      }
                    }}
                  >
                    <strong>Zobacz nadchodzące szkolenia i zapisz się już teraz!</strong>
                  </div>
                </div>
              ),
            },
            {
              id: 2,
              img: HIW2img,
              title: "Płatność",
              desc: "Wszystkie dostępne szkolenia są w tej samej cenie 100 zł brutto za osobę. Możliwość wystawienia faktury z odroczonym terminem płatności. Istnieje możliwość udzielenia rabatu przy zgłoszeniu kilku osób z jednej placówki lub zebrania większej grupy szkoleniowej. Rabat przysługuje również przy uczestnictwie w kilku szkoleniach. W przypadku organizacji szkolenia dla rad pedagogicznych (zdalnego lub stacjonarnego) cenę ustalamy indywidualnie. Proszę o kontakt mailowy.",
              descfn: () => (
                <>
                  <p>Podstawowa cena szkolenia to 100 zł brutto za osobę (sprawdź, jak uzyskać RABAT).</p>
                  <p>
                    Płatności można dokonać offline (przelew na rachunek) lub online podczas procesu rejestracji. Istnieje możliwość wystawienia faktury VAT 0% (także na osoby fizyczne oraz z odroczoną płatnością).
                  </p>
                </>
              ),
            },
            {
              id: 3,
              img: HIW3img,
              title: "Dostęp do platformy",
              desc: "Dzień przed szkoleniem otrzymają Państwo link do platformy MS Teams.",
            },
            {
              id: 4,
              img: HIW4img,
              title: "Szkolenie",
              desc: "Standardowe szkolenie zdalne trwa 5 godzin dydaktycznych (3 godziny 45 minut).",
            },
            {
              id: 5,
              img: HIW5img,
              title: "Materiały",
              desc: "Po szkoleniu wysyłam Państwu prezentację i materiały.",
            },
            {
              id: 6,
              img: HIW6img,
              title: "Certyfikaty",
              desc: "Certyfikat ukończenia szkolenia otrzymają Państwo tego samego dnia.",
            },
          ]}
        />

      </div>

      <div className="container relative mb-24">
        <div className="relative py-10 mt-24">
          <Heading isCenter desc="">
            Prowadząca
          </Heading>
          <BackgroundSection />
          <AuthorDescription rightImg={"/images/ola.jpeg"} />
        </div>

        <SectionGridFilterCard className="clist pb-24 lg:pb-28 mt-24" />
      </div>
    </div>
  );
}

export default MamTeMocHome;
