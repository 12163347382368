import React from "react";
import NavigationItem from "./NavigationItem";
import { NAVIGATION_DEMO } from "data/navigation";
import authServiceClient from "services/authServiceClient";



const renderItem = (item:any) =>{
  
  
  if(!item.admin || (item.admin && authServiceClient.isAuth()))
  {
    return  <NavigationItem key={item.id} menuItem={item} />
  }
  
}

function Navigation() {

  return (

    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-1 relative">
   
     {NAVIGATION_DEMO.map((item) => (
       
        renderItem(item)

       
      ))}
    </ul>
  );
}

export default Navigation;
