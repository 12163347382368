import axios from 'axios';
import ClientConfig from './clientConfig';

export class CourseServiceClient {


    async get(callback){

        try {
            await
                axios.get(ClientConfig.ApiBaseUrl + "course/list",
                    
                    {
                      //  responseType: 'arraybuffer',
                        headers:
                        {
                            'Content-Type': 'application/json',
                           // 'Accept': 'application/pdf',
                           // 'Authorization': this.authService.authToken()
                        }
                    }
                ).then(response => {
                    callback(response.data);
                });
        }
        catch (e) {
            console.log('EventService error.', e);
        }
    }
    
 

    // async email(ids) {

    //     try {
    //         await fetch(Options.APIURL + '/certificates/email', {
    //             method: 'POST',
    //             body: JSON.stringify(

    //                 ids.map((x) => {
    //                     return {
    //                         certId: x,
    //                     };
    //                 })
    //             ),
    //             headers: this.authService.authHeader()
    //         });
    //     }
    //     catch (e) {
    //         console.log('Sending message failed.', e);
    //     }
    // }
}

export default CourseServiceClient;
